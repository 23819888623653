import React, { useRef } from "react";

import transition from "../../transition";
import AboutHero from "./assets/fixie.gif";

import { LocomotiveScrollProvider } from "react-locomotive-scroll";

import "./css/About.css";
import Navbar from "../Nav/Nav";
import Footer from "../Footer/Footer";

const About = () => {
    const containerRef = useRef(null);

    return (
        <>
            <Navbar uppercase={true} />
            <LocomotiveScrollProvider
                options={{
                    smooth: true,
                    smartphone: {
                        smooth: true,
                    },
                    tablet: {
                        smooth: true,
                    },
                }}
                watch={[]}
                containerRef={containerRef}
            >
                <div
                    className="about smooth-scroll"
                    data-scroll-container
                    ref={containerRef}
                    id="scroll-container"
                >
                    <section className="hero-img" data-scroll-section>
                        <div className="hero-img-container">
                            <img src={AboutHero} alt="" />
                        </div>
                    </section>

                    <section className="about-us" data-scroll-section>
                        <div className="about-us-copy">
                            <div className="about-us-copy-p">
                                <span style={{
                                    fontSize: '48px',
                                    lineHeight: '100%'
                                }}>About Me</span>
                                <br />
                                <p >
                                    Software developer with a solid background in computer sciences and several years of development experience during my apprenticeship. <br /> <br />
                                    Passionate about solving complex problems and always looking for new challenges to improve my skills. Curious about new technologies and learning to improve my skills.
                                </p>
                                <br />
                            </div>
                        </div>
                    </section>

                    <section id="about-sticky-wrap-1" data-scroll-section>
                        <div
                            className="about-sticky about-sticky-1"
                            data-scroll
                            data-scroll-sticky
                            data-scroll-target="#about-sticky-wrap-1"
                        >
                            <div className="sticky-content">
                                <div className="sitcky-content-h1">
                                    <h1 className="num" style={{ color: 'black' }}>professional experiences</h1>
                                </div>
                            </div>
                        </div>
                        <div className="about-sticky about-sticky-2">
                            <div className="sticky-content">
                                <div className="sitcky-content-h1 resume">
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;2023 - today <br /> Thales SIX GTS France, CRISTAL&nbsp; Genevilliers
                                    </h1>
                                    <br />
                                    <p >Full-time job <br /><br />
                                        Full-Stack Developer in an internal Thales startup <br /> Big-Data solution<br />
                                        Front-End: React<br />
                                        Back-End: Node JS, Typescript
                                        ElasticSearch Database
                                        DevOps: Jenkins, Docker, Bazel<br />
                                    </p >
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;2020 - 2023 <br /> Thales SIX GTS France, CRISTAL &nbsp;Genevilliers
                                    </h1>
                                    <br />
                                    <p >3-year apprenticeship<br /><br />
                                        First part: Software development and specialized tools for network systems<br />
                                        Technical research and development on certain upgrade requests<br />
                                        Second part: Full-Stack developer<br />
                                        In-house data management solution
                                    </p >
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="about-sticky-wrap-2" data-scroll-section>
                        <div
                            className="about-sticky about-sticky-1"
                            data-scroll
                            data-scroll-sticky
                            data-scroll-target="#about-sticky-wrap-2"
                        >
                            <div className="sticky-content">
                                <div className="sitcky-content-h1">
                                    <h1 className="num" style={{ color: 'black' }}>school</h1>
                                </div>
                            </div>
                        </div>
                        <div className="about-sticky about-sticky-2">
                            <div className="sticky-content">
                                <div className="sitcky-content-h1">
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;2020 - 2023 <br />
                                        EPITA,&nbsp; Kremlin-Bicêtre
                                    </h1>
                                    <br />
                                    <br />
                                    <p >Engineering cycle through apprenticeship
                                    </p>
                                    <br /><br />

                                    <p >
                                        Software engineering, System and Networks, Development and Security <br />Management and knowledge for the company
                                    </p >
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;2016-2020 <br /> Paris Diderot University,&nbsp; Paris
                                    </h1>
                                    <br />
                                    <br />
                                    <p >Bachelor of Physics<br /><br />
                                        Options Computer Sciences (several projects of modeling of physical phenomena) <br />Thermodynamics, Exobiology, and Particle Physics
                                    </p >
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="about-sticky-wrap-3" data-scroll-section>
                        <div
                            className="about-sticky about-sticky-1"
                            data-scroll
                            data-scroll-sticky
                            data-scroll-target="#about-sticky-wrap-3"
                        >
                            <div className="sticky-content">
                                <div className="sitcky-content-h1">
                                    <h1 className="num" style={{ color: 'black' }}>international experiences</h1>
                                </div>
                            </div>
                        </div>
                        <div className="about-sticky about-sticky-2">
                            <div className="sticky-content">
                                <div className="sitcky-content-h1">
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;July 2022 <br /> TSI - Riga,&nbsp;Latvia
                                    </h1>
                                    <br /><br />
                                    <p >
                                        University specialized in transport and telecommunications <br />
                                        Marketing, communication, and social sciences
                                    </p >
                                    <br /><br /><br /><br /><br /><br /><br /><br />
                                    <h1 >
                                        •&nbsp;&nbsp;&nbsp;&nbsp;July 2023 <br /> Algebra University,&nbsp; Zagreb, &nbsp;Croatia
                                    </h1>
                                    <br />
                                    <p >
                                        Wireless communications basics and mathematics
                                    </p >
                                </div>
                            </div>
                        </div>
                    </section>

                    <section data-scroll-section id="footer-section" style={{ padding: '0 2em 2em 2em' }}>
                        <Footer />
                    </section>
                </div >
            </LocomotiveScrollProvider >
        </>
    );
};

export default transition(About);
