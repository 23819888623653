import React, { useEffect, useState } from 'react';
import transition from "../../transition";
import "./Contact.css"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from "split-type";
import Navbar from '../Nav/Nav';

gsap.registerPlugin(ScrollTrigger);
const Contact = () => {

    const [time, setTime] = useState(getCurrentTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(getCurrentTime());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const splitTypes = document.querySelectorAll('.contact-copy-h1');
        splitTypes.forEach((char, i) => {
            const text = new SplitType(char, { types: 'chars' });

            gsap.fromTo(text.chars,
                {
                    opacity: 1
                },
                {
                    opacity: 0.4,
                    stagger: 0.1,
                    duration: 0.5,
                    delay: 0,
                    scrollTrigger: {
                        trigger: char,
                        scrub: false,
                        markers: false,
                    },
                    repeatDelay: 1,
                    repeat: -1,
                });
            gsap.fromTo(text.chars,
                {
                    opacity: 0.4
                },
                {
                    opacity: 1,
                    stagger: 0.1,
                    duration: 0.5,
                    delay: 1,
                    scrollTrigger: {
                        trigger: char,
                        scrub: false,
                        markers: false,
                    },
                    repeatDelay: 1,
                    repeat: -1,
                });
        })
    }, [])

    function getCurrentTime() {
        const now = new Date(new Date().getTime() - new Date().getTimezoneOffset() + 60);
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        return `${hours} : ${minutes} : ${seconds}`;
    }

    return (
        <div>
            <Navbar uppercase={true} />
            <div className="contact-copy">
                <div className="contact-copy-h1">
                    <a href="mailto:mathieu.salliot@yahoo.fr">
                        <h1>Contact</h1>
                    </a>
                </div>
                <div className="contact-copy-text">
                    <p>Mathieu SALLIOT</p>
                    <br />
                    <p>
                        <a href="https://www.linkedin.com/in/mathieusl/">linkedin</a>
                    </p>
                    <br />
                    <p>Paris, FR - {time}</p>
                </div>
            </div>
        </div>
    );
};

export default transition(Contact);
