import React, { useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./Nav.css"

const Navbar = ({ uppercase }) => {

    const menuClass = uppercase ? 'header__menu uppercase' : 'header__menu';
    const [showLinks, setShowLinks] = useState(false);
    const location = useLocation();

    const handleButtonClick = () => {
        setShowLinks(!showLinks);
    };

    const btnColor = useMemo(() => {
        const isActivePage = (path) => {
            return location.pathname === path;
        };

        if (isActivePage("/contact") || isActivePage("/projects")) {
            if (showLinks) { return "#fcfbf1" };
            return "#0E100F";
        }
        return "#fcfbf1";
    }, [location.pathname, showLinks]);


    const hoverColor = useMemo(() => {
        const isActivePage = (path) => {
            return location.pathname === path;
        };

        if (isActivePage("/contact") || isActivePage("/projects")) {
            return "#0E100F";
        }
        return "#fcfbf1";
    }, [location.pathname]);

    return (
        <header>
            <div className="container" id='nav-container' >
                <div className="header__inner">
                    <button id="menu-toggle" className="header__menu-toggle" style={{ color: btnColor }} onClick={handleButtonClick}>
                        Menu<svg xmlns="http://www.w3.org/2000/svg" width="33" height="15" fill="none" viewBox="0 0 33 15">
                            <path fill={btnColor} fillRule="evenodd" d="M.834 1.646a1 1 0 0 1 1-1h30a1 1 0 1 1 0 2h-30a1 1 0 0 1-1-1Z" clipRule="evenodd"
                                style={{
                                    translate: "none",
                                    rotate: "none",
                                    scale: "none",
                                    transformOrigin: "0px 0px"
                                }}
                                data-svg-origin="16.834171295166016 1.6459999680519104" transform="matrix(1,0,0,1,0,0)"></path>
                            <path fill={btnColor} fillRule="evenodd" d="M.834 7.646a1 1 0 0 1 1-1h30a1 1 0 1 1 0 2h-30a1 1 0 0 1-1-1Z" clipRule="evenodd" style={{
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transformOrigin: "0px 0px",
                                opacity: 1
                            }} data-svg-origin="16.833999633789062 7.645999908447266" transform="matrix(1,0,0,1,0,0)"></path>
                            <path fill={btnColor} fillRule="evenodd" d="M.834 13.646a1 1 0 0 1 1-1h30a1 1 0 1 1 0 2h-30a1 1 0 0 1-1-1Z" clipRule="evenodd" style={{
                                translate: "none",
                                rotate: "none",
                                scale: "none",
                                transformOrigin: "0px 0px"
                            }} data-svg-origin="16.833999633789062 13.645999908447266" transform="matrix(1,0,0,1,0,0)"></path>
                        </svg>
                    </button>
                    <div className="header__nav">
                        <nav className="navbar">
                            <div>
                                <ul className={menuClass}>
                                    <li className='header__menu-item'>
                                        <NavLink to="/"
                                            className={(nav) => (nav.isActive ? "header__menu-link nav-active" : "header__menu-link")}
                                            style={{ '--hover-color': hoverColor }}
                                        >Home</NavLink>
                                    </li>
                                    <li className='header__menu-item'>
                                        <NavLink to="/projects" className={(nav) => (nav.isActive ? "header__menu-link nav-active" : "header__menu-link")}
                                            style={{ '--hover-color': hoverColor }}>Projects</NavLink>
                                    </li>
                                    <li className='header__menu-item'>
                                        <NavLink to="/about" className={(nav) => (nav.isActive ? "header__menu-link nav-active" : "header__menu-link")}
                                            style={{ '--hover-color': hoverColor }}>About</NavLink>
                                    </li>
                                    <li className='header__menu-item'>
                                        <NavLink to="/contact" className={(nav) => (nav.isActive ? "header__menu-link nav-active" : "header__menu-link")}
                                            style={{ '--hover-color': hoverColor }}>Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>

                    <div className={`mobile-nav-container ${showLinks ? 'show-links' : ''}`}>
                        <div id="mobile-nav" className="mobile-nav">
                            <div className="mobile-nav__panel--primary" >
                                <div className="mobile-nav__menu-wrapper" >
                                    <nav className="mobile-nav__menu">
                                        <ul className="mobile-nav__list">
                                            <li className="mobile-nav__item">
                                                <NavLink to="/"
                                                    className={(nav) => (nav.isActive ? "mobile-nav__link nav-active" : "mobile-nav__link")}
                                                >Home</NavLink>
                                            </li>
                                            <li className="mobile-nav__item">
                                                <NavLink to="/projects" className={(nav) => (nav.isActive ? "mobile-nav__link nav-active" : "mobile-nav__link")}>Projects</NavLink>
                                            </li>
                                            <li className="mobile-nav__item">
                                                <NavLink to="/about" className={(nav) => (nav.isActive ? "mobile-nav__link  nav-active" : "mobile-nav__link")}>About</NavLink>
                                            </li>
                                            <li className="mobile-nav__item">
                                                <NavLink to="/contact" className={(nav) => (nav.isActive ? "mobile-nav__link  nav-active" : "mobile-nav__link")}>Contact</NavLink>
                                            </li>
                                        </ul>

                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    );
};

export default Navbar;
