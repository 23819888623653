import React from "react";
import "./Projects.css";

import { motion } from "framer-motion";
import transition from "../../transition";

import BeyondImg from "../../Images/BeyondBoat.png";
import Footer from "../Footer/Footer";
import Navbar from "../Nav/Nav";

const Project = () => {
  return (
    <>
      <Navbar uppercase={true}></Navbar>

      <div className="project-container">
        <div className="project-info">
          <motion.div
            className="project-img"
            initial={{
              clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
            }}
            animate={{
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
              transition: {
                duration: 1.75,
                ease: [0.83, 0, 0.17, 1],
                delay: -0.1,
              },
            }}
          >
            <motion.img
              src={BeyondImg}
              alt=""
              initial={{
                scale: 1.5,
              }}
              animate={{
                scale: 1,
                transition: {
                  duration: 1.75,
                  ease: [0.83, 0, 0.17, 1],
                  delay: 0,
                },
              }}
            />
          </motion.div>
          <motion.div
            className="project-description"
            initial={{
              x: -40,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 1.5,
                ease: [0.83, 0, 0.17, 1],
                delay: 0.25,
              },
            }}
          >
            <h2>
              Beyond Boat
            </h2>
            <p>
              A dynamic website crafted with HTML, CSS, and JavaScript, inspired by a Figma template. Explore the seamless fusion of design and functionality in this project showcasing my web development skills.
            </p>
          </motion.div>
        </div>

        <Footer />
      </div >
    </>
  );
};

export default transition(Project);
