import React from "react";
import "./Home.css";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import transition from "../../transition";

import Project1Img from "../../Images/project-1.png";
import Project2Img from "../../Images/project-2.png";
import Project3Img from "../../Images/project-3.png";
import Navbar from "../Nav/Nav";
import Footer from "../Footer/Footer";

const Home = () => {
  const isSmall = window.innerWidth <= 900;

  return (
    <>
      <Navbar uppercase={true} />
      <motion.div className="Home">
        <div className="bg"></div>
        <section className="hero">
          <div className="headers">

            {isSmall ? (
              <>
                <div className="header header-1">
                  <h1>
                    <motion.div
                      initial={{
                        left: -1000,
                      }}
                      animate={{
                        left: 0,
                        transition: {
                          duration: 1.5,
                          ease: [0.83, 0, 0.17, 1],
                          delay: 0.2,
                        },
                      }}
                      className="h1"
                    >
                      MATHIEU&nbsp;
                    </motion.div>
                  </h1>
                  <h1>
                    <motion.div
                      className="h1"
                      initial={{
                        left: 1000
                      }}
                      animate={{
                        left: 0,
                        transition: {
                          duration: 1.5,
                          ease: [0.83, 0, 0.17, 1],
                          delay: 0.2,
                        },
                      }}
                    >
                      &nbsp;SALLIOT
                    </motion.div>
                  </h1>
                </div>
                <div className="header header-2">
                  <h1>
                    <motion.div
                      id="portfolio-h1"
                      className="h1"
                      initial={{
                        top: 800,
                        right: 0,
                        textAlign: 'center',
                      }}
                      animate={{
                        top: 0,
                        margin: 'auto 0',
                        transition: {
                          duration: 1.5,
                          ease: [0.83, 0, 0.17, 1],
                          delay: 1,
                        },
                      }}
                    >
                      PORTFOLIO
                    </motion.div>
                    <div className="h1-revealer-3"></div>
                  </h1>
                </div>
              </>
            )
              : (
                <>
                  <div className="header header-1">
                    <h1>
                      <motion.div
                        initial={{
                          left: -1000,
                        }}
                        animate={{
                          left: 0,
                          transition: {
                            duration: 1.5,
                            ease: [0.83, 0, 0.17, 1],
                            delay: 0.5,
                          },
                        }}
                        className="h1"
                      >
                        MATHIEU
                      </motion.div>
                    </h1>
                    <h1>
                      <motion.div
                        className="h1"
                        initial={{
                          left: 1000
                        }}
                        animate={{
                          left: 0,
                          transition: {
                            duration: 1.5,
                            ease: [0.83, 0, 0.17, 1],
                            delay: 0.5,
                          },
                        }}
                      >
                        SALLIOT
                      </motion.div>
                    </h1>
                  </div>
                  <div className="header header-2">
                    <h1>
                      <motion.div
                        id="portfolio-h1"
                        className="h1"
                        initial={{
                          top: 800,
                          right: 0,
                          textAlign: 'center',
                        }}
                        animate={{
                          top: 0,
                          margin: 'auto 0',
                          transition: {
                            duration: 1.5,
                            ease: [0.83, 0, 0.17, 1],
                            delay: 1,
                          },
                        }}
                      >
                        PORTFOLIO
                      </motion.div>
                    </h1>
                  </div>
                </>)
            }
          </div>
        </section>

        <div className="projects-nav">
          <div className="projects-nav-container">
            <div className="project-item">
              <Link to="/projects">
                <div className="project-link">
                  <div className="project-l">
                    <div className="project-link-img">
                      <img src={Project1Img} alt="" />
                    </div>
                    <div className="project-name">
                      <h2>PROJECTS</h2>
                    </div>
                  </div>
                  <div className="project-date">
                    <p>EXPERIENCE</p>
                    {/* <p>LAST UPDATE JAN 2024</p> */}
                  </div>
                  <div className="project-dir">
                    <p>&#8599;</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="project-item">
              <Link to="/about">
                <div className="project-link">
                  <div className="project-l">
                    <div className="project-link-img">
                      <img src={Project2Img} alt="" />
                    </div>
                    <div className="project-name">
                      <h2>ABOUT ME</h2>
                    </div>
                  </div>
                  <div className="project-date">
                    <p>DEVELOPMENT</p>
                    <p>JAN 2024</p>
                  </div>
                  <div className="project-dir">
                    <p>&#8599;</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="project-item">
              <Link to="/contact">
                <div className="project-link">
                  <div className="project-l">
                    <div className="project-link-img">
                      <img src={Project3Img} alt="" />
                    </div>
                    <div className="project-name">
                      <h2>CONTACT ME</h2>
                    </div>
                  </div>
                  <div className="project-date">
                    <p>CONTACT</p>
                    <p>JAN 2024</p>
                  </div>
                  <div className="project-dir">
                    <p>&#8599;</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </motion.div >

    </>
  );
};

export default transition(Home);